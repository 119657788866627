<template>
  <section class="modal--container auth--container">
    <div class="title--text" v-if="$mq !== 'xs'">
      {{ $t('loginRegister.register') }}
    </div>
    <social-media-auth />
    <hr class="separator" />
    <b-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
    </b-alert>
    <form id="loginForm" @submit.prevent="register">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('fullName') }">
        <v-text-field
          :label="$t('loginRegister.fullname')"
          outlined
          :hide-details="true"
          v-model="fullName"
          name="full_name"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          :label="$t('loginRegister.email')"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          :label="$t('loginRegister.pass')"
          outlined
          :hide-details="true"
          v-model="password"
          name="password"
          class="basic--input"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('confirmPassword') }"
      >
        <v-text-field
          :label="$t('loginRegister.confirmPass')"
          outlined
          :hide-details="true"
          v-model="confirmPassword"
          name="confirm_password"
          class="basic--input"
          :type="showConfirmPass ? 'text' : 'password'"
          :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showConfirmPass = !showConfirmPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('confirmPassword')">{{
          validation.firstError('confirmPassword')
        }}</span>
      </div>
      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        {{ $t('loginRegister.register') }}
      </button>
    </form>

    <div class="bottom--part">
      <span class="mr-2">{{ $t('loginRegister.message2') }}</span>
      <a href="javascript:" @click="showModal('modal-login')" class="auth--link">{{
        $t('loginRegister.messageBold2')
      }}</a>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const SocialMediaAuth = () => import('@/components/auth/social-media-auth');

export default {
  props: ['formType'],
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      fullName: null,
      isLoading: false,
      remember: false,
      showAlert: false,
      messageAlert: null,
      alertType: '',
      showPass: false,
      showConfirmPass: false,
    };
  },
  components: {
    SocialMediaAuth,
  },
  methods: {
    async showModal(modalName) {
      this.$modal.hide('modal-register');
      if (modalName === 'modal-forget-pass') {
        this.$modal.show(modalName);
      } else {
        if (this.formType === 'page') {
          this.$router.push('/login');
        } else {
          this.$modal.show(modalName);
        }
      }
    },
    async register() {
      const isValid = await this.$validate();
      if (isValid) {
        const { data } = await this.$store.dispatch('global/registerUser', {
          email: this.email,
          name: this.fullName,
          password: this.password,
          password_confirmation: this.confirmPassword,
          mobile: false,
        });
        console.log('register user', data);
        if (data.user) {
          this.messageAlert = this.$i18n.t('register.checkActivation');
          this.alertType = 'success';
          this.showAlert = true;
          if (this.formType !== 'page') {
            this.$modal.hide('modal-register');
            await this.$swal(this.$t('general.success'), this.messageAlert, 'success');
          }
        } else {
          this.messageAlert = this.$i18n.t('register.formError');
          this.alertType = 'danger';
          this.showAlert = true;
        }
      }
    },
    async getInvitationData(token_) {
      try {
        this.loading = true;
        const { data } = await this.$http.get('/api/v2/auth/get_invitation_data', {
          params: {
            invite_member_partner_token: token_,
          },
        });
        this.email = data.data.emailInviteMember;
      } finally {
        this.loading = false;
      }
    },
  },
  validators: {
    email: {
      debounce: 250,
      validator: function(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.email.invalid'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }))
          .custom(async () => {
            const { data } = await this.$store.dispatch('global/checkEmail', value);
            if (!data.valid) {
              return this.$i18n.t('errors.loginRegister.email.exist');
            }
          });
      },
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword)
          .required(this.$i18n.t('errors.loginRegister.confirmPass.required'))
          .match(password, this.$i18n.t('errors.loginRegister.confirmPass.notMatched'));
      }
    },
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.fullName.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.fullName.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 100 }));
    },
  },
  created() {
    // extend('check_email', {
    //   message: field => `${this.capitalize(field)} already registered. Please use another e-mail.`,
    //   validate: value =>
    //     this.$store.dispatch('global/checkEmail', value).then(({ data }) => ({
    //       valid: data.valid === true,
    //     })),
    // });
    if (this.$route.query.invite_member_partner_token) {
      this.disableEmail = true;
      this.getInvitationData(this.$route.query.invite_member_partner_token);
    }
  },
  mounted() {
    this.validation.reset();
  },
};
</script>
