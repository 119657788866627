<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('loginRegister.register') }}
      </div>
      <register formType="modal" />
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import Register from '@/components/auth/register';
export default {
  components: {
    Register,
  },
  data: () => ({
    modalName: 'modal-register',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
